export default [
  {
    type: "selection",
    width: 38,
    align: "center",
  },
  {
    title: "Expires On",
    key: "ExpiresOn",
    sortable: true,
    minWidth: 50,
  },
  
  {
    title: "Status",
    key: "Status",
    // sortable: true,
    minWidth: 50,
  },
  {
    title: "Title",
    key: "Title",
    minWidth: 150,
    // sortable: true,
  },
  // {
  //   title: "Body",
  //   key: "Body",
  //   // sortable: true,
  //   minWidth: 280,
  // },

];
